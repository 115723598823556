<template>
  <el-card class="question-config">

    <split-pane :min-percent='15' :default-percent='15' split="vertical" class="body">
      <template slot="paneL">

        <div
          class="menu p-l-10"
          v-if="tabIndex === 0"
          >
          <div class="title">试题配置列表</div>
          <el-menu :default-active="actQuestionType.index" v-if="tabIndex === 0">
            <el-menu-item v-for="item in styleClassesData" :key="item.index" :index="item.index" @click="switchQuestionType(item)">{{ item.name }}</el-menu-item>
          </el-menu>
        </div>

        <!--  -->
        <div class="menu" v-else-if="tabIndex === 1">
          <div class="title">
            <span>批量配置列表</span>
            <el-button class="style-tool-button--text f-r-btn" @click="addConfig">+新增配置</el-button>
          </div>
          <el-menu :default-active="actStyleBatch.id">
            <el-menu-item v-for="item in questionBatch" :key="item.index" :index="item.id" @click="switchStyleBatch(item)">
              <div class="menu-item">
                <div>{{ item.name }}</div>
                <div>
                  <el-button type="text" class="del-buton" @click.stop="editItem(item)">修改</el-button>
                  <el-button type="text" class="del-buton" @click.stop="delItem(item)">删除</el-button>
                </div>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
      </template>
      <template slot="paneR">
        <div class="body-right"  v-if="config && (bantchActTab === 'style' || tabIndex === 0)">
          <!-- 版式 & 表单 -->
          <div>
            <!-- 表单 -->
            <div class="config">

              <div class="with-border">
                <el-tabs
                  v-model="actComponent" tab-position="left"
                  v-if="config"
                  >
                  <!-- <el-tab-pane label="整体配置" name="_self" lazy>
                    <el-form :inline="true" size="mini" label-width="70px">
                      <el-form-item label="试题显示：">
                        <el-radio-group v-model="config.display._self">
                          <el-radio :label="true">显示试题内容</el-radio>
                          <el-radio :label="false">不显示试题内容</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <br>
                      <el-form-item label="内容显示：">
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'serial_number')" @input="setDisplay($event, config.display, 'serial_number')">题号</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'source')" @input="setDisplay($event, config.display, 'source')">来源</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'body')" @input="setDisplay($event, config.display, 'body')">题干</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'choices')" @input="setDisplay($event, config.display, 'choices')">选项</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'choice_letter')" @input="setDisplay($event, config.display, 'choice_letter')">选项字母</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'choice_option')" @input="setDisplay($event, config.display, 'choice_option')">选项内容</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'answer')" @input="setDisplay($event, config.display, 'answer')">答案</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'analysis')" @input="setDisplay($event, config.display, 'analysis')">解析</el-checkbox>
                        <el-checkbox :value="getDisplay(config.display, placeholder.display, 'answer_space')" @input="setDisplay($event, config.display, 'answer_space')">作答空间</el-checkbox>
                      </el-form-item>
                      <el-row>
                        <el-form-item label="前节间距：">
                          <format-input type="number" v-model="config.config._self.margin.top" :placeholder="placeholder.config._self.margin.top" />
                        </el-form-item>
                        <el-form-item label="后节间距：">
                          <format-input type="number" v-model="config.config._self.margin.bottom" :placeholder="placeholder.config._self.margin.bottom" />
                        </el-form-item>
                      </el-row>
                      <el-form-item label="整体缩进：">
                        <format-input type="number" v-model="config.config._self.margin.left" :placeholder="placeholder.config._self.margin.left" />
                      </el-form-item>
                      <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.style._self" :placeholder="placeholder.style._self" :exclude="['text_align', 'line_spacing']">
                      </BaseStyle>
                      <el-form-item label="下划留空：">
                        <el-radio-group v-model="config.config._self.blank_both_sides_space">
                          <el-radio :label="'none'">无</el-radio>
                          <el-radio :label="'space'">有空格</el-radio>
                        </el-radio-group>
                        <span>
                          （用于短填空两边加空格撑开下划线等情况）
                        </span>
                      </el-form-item>
                      <br>
                      <el-row v-if="isBatch">
                        <el-form-item label="连排效果：">
                          <el-select v-model="config.config._self.continuous_array.effect" :placeholder="{ continue: '连排显示', change_line: '换行显示' }[placeholder.config._self.continuous_array.effect] || '换行显示'"
                            @change="effectChange" :popper-append-to-body="false" class="style-tool-select" clearable>
                            <el-option label="连排显示" value="continue" />
                            <el-option label="换行显示" value="change_line" />
                          </el-select>
                        </el-form-item>
                        <el-form-item label="连排距离：" v-if="config.config._self.continuous_array.effect === 'continue'">
                          <format-input type="number" v-model="config.config._self.continuous_array.width" :placeholder="placeholder.config._self.continuous_array.width" />
                        </el-form-item>
                      </el-row>
                      <el-row v-if="isBatch">
                        <el-row style="color: 000; padding: 10px">
                          <span>对位效果：</span>
                          <el-form-item label="对位个数：" label-width="80px" style="padding: 0;">
                            <unit-input v-model.number="config.config._self.children_dw.per_line" :placeholder="placeholder.config._self.children_dw.per_line" unit="字" @input="changePerLine" />
                          </el-form-item>
                        </el-row>
                        <el-row class="m-l-40">
                          <el-form-item :label="`第${index + 1}个选项内容宽度：`" v-for="(item, index) in config.config._self.children_dw.spacing" :key="index" label-width="160px">
                            <unit-input v-model.number="config.config._self.children_dw.spacing[index]" :placeholder="placeholder.config._self.children_dw.spacing[index] || '1'" unit="字" />
                            <span class="unit">&nbsp;字</span>
                          </el-form-item>
                        </el-row>
                      </el-row>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane label="试题结构" name="content_split" lazy>
                    <el-form :inline="true" size="mini" label-width="70px">
                      <el-row>
                        <el-form-item label="题组顺序：">
                          <el-radio-group v-model="config.config.content_split.refer_order">
                            <el-radio label="default">默认</el-radio>
                            <el-radio label="answer-analysis">先答案后解析</el-radio>
                            <el-radio label="analysis-answer">先解析后答案</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item label="显示方式：">
                          <el-radio-group v-model="config.config.content_split.refer_alternate">
                            <el-radio :label="false">整体显示答案解析（默认）</el-radio>
                            <el-radio :label="true">逐题答案解析</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                      <hr>
                      <div v-if="!config.config.content_split.refer_alternate">
                        <el-row>
                          <el-form-item label="答案组：" class="format-style-form-item">
                            <el-row class="m-l-40">
                              <div class="base-item" style="width: 100%;">
                                <span class="base-label" style="width: 70px">答案换行：</span>
                                <el-radio-group v-model="config.config.content_split.answer.line_break">
                                  <el-radio :label="false">不换行（默认）</el-radio>
                                  <el-radio :label="true">换行</el-radio>
                                </el-radio-group>
                              </div>
                              <div class="base-item" style="width: 100%;">
                                <span class="base-label" style="width: 70px">答案间隔：</span>
                                <el-input v-model="config.config.content_split.answer.delimiter" :placeholder="placeholder.config.content_split.answer.delimiter" class="style-tool-input" />
                              </div>
                              <hr>
                              <div class="base-item" style="width: 100%;">
                                <span class="base-label" style="width: 70px">前插入：</span>
                                <el-radio-group :value="config.config.content_split.answer.before.type || 'text'" @input="beforeOrAfterTypeChange($event, config.config.content_split.answer.before)">
                                  <el-radio label="text">文字模式</el-radio>
                                  <el-radio label="img">图片模式</el-radio>
                                </el-radio-group>
                              </div>
                              <template v-if="config.config.content_split.answer.before.type === 'img'">
                                <Decorations :decorations="config.config.content_split.answer.before.decorations" :styleData="styleData" mode="vDom" />
                              </template>
                              <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.config.content_split.answer.before" v-else>
                                <template slot="before">
                                  <div class="base-item">
                                    <el-input v-model="config.config.content_split.answer.before.content" :placeholder="placeholder.config.content_split.answer.before.content" class="style-tool-input" />
                                  </div>
                                </template>
                              </BaseStyle>
                            </el-row>
                          </el-form-item>
                        </el-row>
                        <hr>
                        <el-row>
                          <el-form-item label="解析组：" class="format-style-form-item">
                            <el-row class="m-l-40">
                              <div class="base-item" style="width: 100%;">
                                <span class="base-label" style="width: 70px">解析换行：</span>
                                <el-radio-group v-model="config.config.content_split.analysis.line_break">
                                  <el-radio :label="false">不换行（默认）</el-radio>
                                  <el-radio :label="true">换行</el-radio>
                                </el-radio-group>
                              </div>
                              <div class="base-item" style="width: 100%;">
                                <span class="base-label" style="width: 70px">解析间隔：</span>
                                <el-input v-model="config.config.content_split.analysis.delimiter" :placeholder="placeholder.config.content_split.analysis.delimiter" class="style-tool-input" />
                              </div>
                              <hr>
                              <div class="base-item" style="width: 100%;">
                                <span class="base-label" style="width: 70px">前插入：</span>
                                <el-radio-group :value="config.config.content_split.analysis.before.type || 'text'" @input="beforeOrAfterTypeChange($event, config.config.content_split.analysis.before)">
                                  <el-radio label="text">文字模式</el-radio>
                                  <el-radio label="img">图片模式</el-radio>
                                </el-radio-group>
                              </div>
                              <template v-if="config.config.content_split.analysis.before.type === 'img'">
                                <Decorations :decorations="config.config.content_split.analysis.before.decorations" :styleData="styleData" mode="vDom" />
                              </template>
                              <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.config.content_split.analysis.before" v-else>
                                <template slot="before">
                                  <div class="base-item">
                                    <el-input v-model="config.config.content_split.analysis.before.content" :placeholder="placeholder.config.content_split.analysis.before.content" class="style-tool-input" />
                                  </div>
                                </template>
                              </BaseStyle>
                            </el-row>
                          </el-form-item>
                        </el-row>
                      </div>
                      <div v-else>
                        <el-row>
                          <el-form-item label="">
                            <span>试题间的排列方式：</span>
                            <el-radio-group v-model="config.config.content_split.cross_cfg.answer_analysis_arrangement_mode">
                              <el-radio label="break_line">换行</el-radio>
                              <el-radio label="1">空一个字</el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </el-row>
                      </div>
                    </el-form>
                  </el-tab-pane> -->
                  <el-tab-pane label="题号" name="serial_number" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.serial_number && questionConfig.serial_number.fbd.enable_fbd"
                      v-model="questionConfig.serial_number.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="题干" name="body" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.body && questionConfig.body.fbd.enable_fbd"
                      v-model="questionConfig.body.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="选项" name="choices" v-if="isChoice" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.choices && questionConfig.choices.fbd.enable_fbd"
                      v-model="questionConfig.choices.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="选项字母" name="choice_letter" v-if="isChoice" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.choice_letter && questionConfig.choice_letter.fbd.enable_fbd"
                      v-model="questionConfig.choice_letter.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="括号配置" name="bracket" v-if="isChoice" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.bracket && questionConfig.bracket.fbd.enable_fbd"
                      v-model="questionConfig.bracket.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="答案" name="answer" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.answer && questionConfig.answer.fbd.enable_fbd"
                      v-model="questionConfig.answer.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="解析" name="analysis" lazy>
                    <FbdEditor
                      v-if="questionConfig && questionConfig.analysis && questionConfig.analysis.fbd.enable_fbd"
                      v-model="questionConfig.analysis.fbd.code_template"
                      :options="{ height: 150 }"
                    />
                  </el-tab-pane>
                  <!-- <el-tab-pane label="插图" name="img" lazy>
                    <el-form :inline="true" size="mini" label-width="70px">
                      <el-row>
                        <el-form-item label="插图布局：">
                          <el-radio-group v-model="config.html._self.img.display" @change="changeDisplay">
                            <el-radio label="">默认</el-radio>
                            <el-radio label="inline">嵌入行内</el-radio>
                            <el-radio label="block">独占一行</el-radio>
                            <el-radio label="float">浮动</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                      <el-row  v-if="config.html._self.img.display === 'block'">
                        <el-form-item label="对齐方式：">
                          <el-radio-group v-model="config.html._self.img.align">
                            <el-radio label="left">居左</el-radio>
                            <el-radio label="center">居中</el-radio>
                            <el-radio label="right">居右</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                      <el-row v-if="config.html._self.img.display === 'float'">
                        <el-form-item label="起始位置：">
                          <el-radio-group v-model="config.html._self.img.insert_at">
                            <el-radio label="body">题目</el-radio>
                            <el-radio label="choices">选项</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                      <el-row v-if="config.html._self.img.display === 'float'">
                        <el-form-item label="浮动位置：">
                          <el-radio-group v-model="config.html._self.img.align">
                            <el-radio label="left">左浮动</el-radio>
                            <el-radio label="right">右浮动</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-row>
                      <el-form-item label="左侧距离：">
                        <format-input type="number" v-model="config.html._self.img.margin.left" :placeholder="placeholder.html._self.img.margin.left" />
                      </el-form-item>
                      <el-form-item label="上侧距离：">
                        <format-input type="number" v-model="config.html._self.img.margin.top" :placeholder="placeholder.html._self.img.margin.top" />
                      </el-form-item>
                      <el-row>
                        <el-form-item label="右侧距离：">
                          <format-input type="number" v-model="config.html._self.img.margin.right" :placeholder="placeholder.html._self.img.margin.right" />
                        </el-form-item>
                        <el-form-item label="下侧距离：">
                          <format-input type="number" v-model="config.html._self.img.margin.bottom" :placeholder="placeholder.html._self.img.margin.bottom" />
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item label="题干插图文本：" label-width="100px" label-position="left"></el-form-item>
                      </el-row>
                      <el-row class="m-l-10">
                        <BaseStyle :extra_font_list="styleData.extra_font_list" v-model="config.html.body.img.desc.text_style" :placeholder="config.html.body.img.desc.text_style" :exclude="['line_spacing']">
                          <el-form-item label="文本：" slot="before">
                            <el-input class="style-tool-input" v-model="config.html.body.img.desc.text"></el-input>
                          </el-form-item>
                        </BaseStyle>
                      </el-row>
                      <el-row>
                        <el-form-item label="解析插图文本：" label-width="100px" label-position="left"></el-form-item>
                      </el-row>
                      <el-row class="m-l-10">
                        <BaseStyle :extra_font_list="styleData.extra_font_list" v-model="config.html.analysis.img.desc.text_style" :placeholder="config.html.analysis.img.desc.text_style" :exclude="['line_spacing']">
                          <el-form-item label="文本：" slot="before">
                            <el-input class="style-tool-input" v-model="config.html.analysis.img.desc.text"></el-input>
                          </el-form-item>
                        </BaseStyle>
                      </el-row>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane label="表格" name="table" lazy>
                    <el-form :inline="true" size="mini" label-width="120px">
                      <el-row style="color: 000; padding: 10px">表格框线（外框）：</el-row>
                      <el-row class="m-l-40">
                        <el-form-item label="边框类型：">
                          <el-select v-model="config.html._self.table.border.outer.type" :placeholder="placeholder.html._self.table.border.outer.type" :popper-append-to-body="false" class="style-tool-select">
                            <el-option v-for="(value, key) in borderType" :key="key" :label="value" :value="key" />
                          </el-select>
                        </el-form-item>
                        <el-form-item label="框线颜色：">
                          <Color :value="config.html._self.table.border.outer.color" :placeholder="placeholder.html._self.table.border.outer.color" :globalColor="styleData.book_default_style.global_color" />
                        </el-form-item>
                        <el-form-item label="边框粗细：">
                          <el-select v-model="config.html._self.table.border.outer.weight" :placeholder="placeholder.html._self.table.border.outer.weight" :popper-append-to-body="false" class="style-tool-select">
                            <el-option v-for="(value, key) in borderWeight" :key="key" :label="value" :value="key" />
                          </el-select>
                        </el-form-item>
                      </el-row>
                      <el-row style="color: 000; padding: 10px">表格框线（内框）：</el-row>
                      <el-row class="m-l-40">
                        <el-form-item label="边框类型：">
                          <el-select v-model="config.html._self.table.border.inner.type" :placeholder="placeholder.html._self.table.border.inner.type" :popper-append-to-body="false" class="style-tool-select">
                            <el-option v-for="(value, key) in borderType" :key="key" :label="value" :value="key" />
                          </el-select>
                        </el-form-item>
                        <el-form-item label="框线颜色：">
                          <Color :value="config.html._self.table.border.inner.color" :placeholder="placeholder.html._self.table.border.inner.color" :globalColor="styleData.book_default_style.global_color" />
                        </el-form-item>
                        <el-form-item label="边框粗细：">
                          <el-select v-model="config.html._self.table.border.inner.weight" :placeholder="placeholder.html._self.table.border.inner.weight" :popper-append-to-body="false" class="style-tool-select">
                            <el-option v-for="(value, key) in borderWeight" :key="key" :label="value" :value="key" />
                          </el-select>
                        </el-form-item>
                      </el-row>

                      <el-row style="color: 000; padding: 10px">整体效果</el-row>
                      <el-row class="m-l-40">
                        <BaseStyle :extra_font_list="styleData.extra_font_list" :styleData="styleData" v-model="config.html._self.table" :placeholder="placeholder.html._self.table" :exclude="['line_spacing']">
                          <template slot="before">
                            <el-form-item label="全部底色：" label-width="70px">
                              <Color :value="config.html._self.table.fill.crma" :placeholder="placeholder.html._self.table.fill.crma" :globalColor="styleData.book_default_style.global_color" />
                            </el-form-item>
                          </template>
                        </BaseStyle>
                      </el-row>
                      <el-row style="color: 000; padding: 10px">第一行效果</el-row>
                      <el-row class="m-l-40">
                        <BaseStyle :extra_font_list="styleData.extra_font_list" :styleData="styleData" v-model="config.html._self.table.font_style.top" :placeholder="placeholder.html._self.table.font_style.top" :exclude="['line_spacing']">
                          <template slot="before">
                            <el-form-item label="底色：" label-width="70px">
                              <Color :value="config.html._self.table.fill.top" :placeholder="placeholder.html._self.table.fill.top" :globalColor="styleData.book_default_style.global_color" />
                            </el-form-item>
                          </template>
                        </BaseStyle>
                      </el-row>
                      <el-row style="color: 000; padding: 10px">第一列效果</el-row>
                      <el-row class="m-l-40">
                        <BaseStyle :extra_font_list="styleData.extra_font_list" :styleData="styleData" v-model="config.html._self.table.font_style.left" :placeholder="placeholder.html._self.table.font_style.left" :exclude="['line_spacing']">
                          <template slot="before">
                            <el-form-item label="底色：" label-width="70px">
                              <Color :value="config.html._self.table.fill.left" :placeholder="placeholder.html._self.table.fill.left" :globalColor="styleData.book_default_style.global_color" />
                            </el-form-item>
                          </template>
                        </BaseStyle>
                      </el-row>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane label="关键词" name="_keyword" lazy>
                    <HtmlKeyWord v-model="config.html._self._keyword" :extra_font_list="styleData.extra_font_list" :styleData="styleData" />
                  </el-tab-pane>
                  <el-tab-pane label="方框效果" name="fk" lazy>
                    <el-form :inline="true" size="mini" label-width="70px" class="fk-config">
                      <el-form-item label="应用部分：">
                        <el-select v-model="config.config.fk.group" :placeholder="groupMap[placeholder.config.fk.group] || ''" :popper-append-to-body="false" clearable class="style-tool-select m-r-10">
                          <el-option label="题号+题干" value="serial_number_body"></el-option>
                          <el-option label="答案+解析" value="answer_analysis"></el-option>
                          <el-option label="仅答案" value="answer"></el-option>
                          <el-option label="仅解析" value="analysis"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                    <FkConfig v-model="config.config.fk" :styleData="styleData" />
                  </el-tab-pane> -->
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </template>
    </split-pane>
    <el-dialog
      :title="this.actItem ? '修改试题配置' : '新增试题配置'"
      @closed="dialogClose"
      destroy-on-close
      :visible.sync="dialogVisible"
      width="600px">
      <el-form :model="form" :rules="rules" ref="form" label-width="140px">
        <el-form-item label="请输入配置名称：" prop="name">
          <el-input placeholder="请输入名称" style="width: 400px;" size="small" v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="confirm" size="mini">{{ this.actItem ? '确认修改' : '确认创建' }}</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import SplitPane from 'vue-splitpane';
import { defaultFormat } from './assets/format.js';
import { defaultQuestion } from '@/scripts/styleToolDefault';
import { mergePeer, mergeDefault, setId } from '@/scripts/helper';
import { iterateNode } from '@/scripts/treeHelper';
import { fontMargin } from '@/assets/styleTool/map';
import { parser } from '@/scripts/convertLatex';
import _ from 'lodash';

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  name: 'questionConfig',
  components: {
    SplitPane,
    FbdEditor
  },
  props: {
    styleData: {
      type: Object,
      required: true
    },
    json: {
      type: Array,
      required: true
    }
  },
  created () {
    const questions = {
      question: [
        { name: '全部试题', index: '0', node_type: 'question', model: 'all_type' }
      ],
      true_or_false: [],
      choice: [
        { name: '全部选择题', index: '0', node_type: 'question', question_type: 'choice', model: 'all_type' }
      ],
      blank: [],
      material: [],
      other: []
    };
    Object.keys(questions).forEach(k => {
      let v = questions[k];
      if (k === 'question') return;

      if (!v.length) {
        delete questions[k];
      } else {
        v.forEach(({ level, node, node_type }) => {
          if (k === 'material') level += 1;
          questions.question[level] = {
            name: `${this.level[level - 1]}级试题`,
            level,
            model: 'all_type',
            node_type,
            node
          };
        });
        questions[k] = v = v.filter(item => item);
        if (!questions.question[0].node) questions.question[0].node = v[0].node;
      }
    });
    Object.keys(questions).forEach(k => {
      const v = questions[k];
      if (k === 'question') return;
      if (v.length === 1) {
        delete v[0].level;
        v[0].name = `全部${this.questionTypeMap[v[0].question_type]}`;
      } else {
        const { node_type, question_type, model, node } = v[0];
        v.unshift({
          name: `全部${this.questionTypeMap[question_type]}`,
          node_type,
          model,
          question_type,
          node
        });
      }
    });

    this.styleClasses = Object.keys(questions).map((k, index) => {
      return {
        name: questions[k][0].name,
        index: `${index}`,
        children: questions[k],
        key: k,
        hidden: !questions[k][0].node
      };
    });
    this.switchQuestionType(this.styleClasses[0]);
  },
  data () {
    return {
      cfgPickSubQuestion: false,

      latexParse: parser,
      showPath: false,
      questionTypeMap: {
        'true_or_false': '判断题',
        'choice': '选择题',
        'blank': '填空题',
        'material': '材料题',
        'other': '作答题'
      },
      actItem: null,
      dialogVisible: false,
      bantchActTab: 'style',
      fontMargin,
      borderType: {
        solid: '实线',
        none: '无',
        dashed: '短虚线'
      },
      level: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      borderWeight: {
        1: '1（极粗）',
        2: '2',
        3: '3（粗）',
        4: '4',
        5: '5（默认）',
        6: '6',
        7: '7（细）',
      },
      groupMap: {
        serial_number_body: '题号+题干',
        answer_analysis: '答案+解析',
        answer: '仅答案',
        analysis: '仅解析'
      },
      tabIndex: 0,
      defaultFormat: defaultFormat(),
      closed: false,
      tabs: [
        { name: '试题配置列表', index: 0 },
        { name: '试题批量配置', index: 1 },
      ],
      config: null,
      placeholder: null,
      actFormat: {
        data: {}
      },
      condition: {
        formatModel: '',
        search: ''
      },
      form: {
        name: ''
      },
      rules: {
        name: [{ validator: this.validator, trigger: 'change' }]
      },
      formatData: [],
      actComponent: '_self',
      styleClasses: [],
      actStyle: {},
      actStyleBatch: {},
      actQuestionType: {},
      conditions: {
        listKeyword: '',
        currentPage: 1,
        level: ''
      },
    };
  },
  watch: {
    tabIndex: {
      handler (val) {
        this.watchTab(val);
      }
    },
    actStyle: {
      deep: true,
      handler (val) {
        this.switchStyleClass(val);
      }
    }
  },
  computed: {
    styleClassesData () {
      return this.styleClasses;
    },
    questionConfig () {
      const { class_cfg_list } = this.styleData.components[0];
      let questionClass = null;
      if (!this.isChoice) {
        questionClass = class_cfg_list.find(item => item.identity.node_type === 'question' && !item.identity.content?.question_type && !item.identity.content?.level && !item.identity.tag_key);
      } else {
        questionClass = class_cfg_list.find(item => item.identity.node_type === 'question' && item.identity.content?.question_type === 'choice');
      }
      return questionClass.config;
    },
    isBatch () {
      return !!this.config.node_id_list;
    },
    selectedList () {
      const nodeList = [];
      for (const { node } of iterateNode(this.json)) {
        if (node.node_type === 'question') nodeList.push(node);
      }
      const nodeIdList = this.config.node_id_list;
      const selectedList = nodeIdList.map(item => {
        const node = _.cloneDeep(nodeList.find(node => node.node_id === item));
        if (!node) return;
        const content = node.content;
        const div = document.createElement('div');
        div.innerHTML = content.body;
        content._body = div.innerText;
        return Object.assign(node, { content });
      });
      return selectedList.filter(item => item);
    },
    listData () {
      if (!this.json) {
        return {
          list: [],
          filterList: [],
          total: 0
        };
      }
      const filterData = [];
      const { listKeyword, currentPage, level } = this.conditions;
      const json = _.cloneDeep(this.json);
      for (const { node, parent } of iterateNode(json)) {
        node.path = parent.path ? `${parent.path} - ` + (parent?.node_name || '') : (parent?.node_name || '');
        if (node.node_type === 'question') {
          const { body, serial_number, analysis } = node.content;
          if (
            (!listKeyword || body?.indexOf(listKeyword) >= 0 || serial_number?.indexOf(listKeyword) >= 0 || analysis?.indexOf(listKeyword) >= 0 || node.path.indexOf(listKeyword) >= 0) &&
              (!level || level === node.content?.level)
          ) {
            const body = node.content.body;
            const temp = document.createElement('div');
            temp.innerHTML = body;
            node.content._body = listKeyword ? temp.innerText.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`) : temp.innerText;
            node.path = listKeyword ? node.path.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`) : node.path;
            if (listKeyword) {
              node.content.body && (node.content.body = node.content?.body.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`));
            }
            filterData.push(node);
          }
        }
      }
      const total = filterData.length;
      return {
        total,
        filterList: filterData,
        list: filterData.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10),
      };
    },
    checkedAll: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.filterList.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { filterList } = this.listData;
        if (filterList.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          filterList.forEach(item => {
            !nodeIdList.includes(item.node_id) && nodeIdList.push(item.node_id);
          });
        } else {
          filterList.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    checked: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.list.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { list } = this.listData;
        if (list.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          list.forEach(item => {
            !nodeIdList.includes(item.node_id) && nodeIdList.push(item.node_id);
          });
        } else {
          list.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    isChoice () {
      const { config } = this;
      if (!config) return false;
      const { identity } = config;
      const question_type = identity?.content?.question_type;
      if (question_type === 'choice' || !identity) return true;
      return false;
    },
    jsonProp () {
      let json = this.actStyle?.node;
      if (!json) {
        return false;
      }
      json = _.cloneDeep(json);
      delete json.children;
      return [json];
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
    questionBatch () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      return node_batch_cfg_list?.filter(item => item.node_type === 'question') || [];
    },

  },
  methods: {

    // 从嵌套数据中选取 node 节点
    findNodeItemById (node, node_id) {
      const v = this;

      // 初始化传入项为 json obj
      if (Array.isArray(node)) {
        node = { node_id: '', children: node };
      }

      if (node.node_id === node_id) {
        return node;
      } else if (node.children) {
        for (const i in node.children) {
          const res = v.findNodeItemById(node.children[i], node_id);
          if (res) {
            return res;
          }
        }
      }

      return null;
    },

    // 把子题也勾选
    pickSubQuestion (item) {
      if (!this.cfgPickSubQuestion) {
        return;
      }
      const v = this;

      const node = v.findNodeItemById(v.json, item.node_id);

      setTimeout(function () {
        const mark = v.config.node_id_list.indexOf(item.node_id) >= 0;

        if (node?.children?.length) {
          for (const j in node.children) {
            const subItem = node.children[j];
            if (subItem.node_type === 'question') {
              // 加入勾选
              if (mark && !v.config.node_id_list.includes(subItem.node_id)) {
                v.config.node_id_list.push(subItem.node_id);
              } else if (!mark && v.config.node_id_list.includes(subItem.node_id)) { // 删除勾选
                v.config.node_id_list.splice(
                  v.config.node_id_list.indexOf(subItem.node_id),
                  1
                );
              }
            }
          }
        }
      }, 200);
    },

    beforeOrAfterTypeChange (val, position) {
      if (val === 'text') {
        position.type = '';
      } else {
        position.type = 'img';
      }
    },
    changePerLine (val) {
      val = parseInt(val);
      if (!val) return;
      const arr = [];
      for (let i = 0; i < val - 1; i++) {
        arr[i] = '';
      }
      this.config.config._self.children_dw.spacing = arr;
    },
    effectChange (val) {
      if (val !== 'continue') {
        this.config.config._self.continuous_array.width = '';
      }
    },
    choicePerLineChange (val) {
      if (val !== 1 && this.config.config.choices.option_spacing.length) {
        this.config.config.choices.option_spacing = [];
      }
    },
    getItem (item) {
      return item?.content?.serial_number + item?.content?._body;
    },
    delNode (node_id) {
      const nodeIdList = this.config.node_id_list;
      const index = nodeIdList.indexOf(node_id);
      nodeIdList.splice(index, 1);
    },
    handleNodeList (nodeId) {
      const nodeList = this.config.node_id_list;
      const index = nodeList.indexOf(nodeId);
      if (index >= 0) {
        nodeList.splice(index, 1);
      } else {
        nodeList.push(nodeId);
      }
    },
    confirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        const { node_batch_cfg_list } = this.styleData.components[0];
        if (this.form.id) {
          const index = node_batch_cfg_list.findIndex(item => item.id === this.form.id);
          node_batch_cfg_list.splice(index, 1, this.form);
          this.actStyleBatch = this.config = this.form;
        } else {
          const item = defaultQuestion({ id: setId(), name: this.form.name, node_id_list: [], node_type: 'question' });
          node_batch_cfg_list.push(item);
          this.actStyleBatch = this.config = item;
        }
        this.dialogVisible = false;
      });
    },
    editItem (val) {
      this.form = _.cloneDeep(val);
      this.dialogVisible = true;
    },
    delItem (val) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { node_batch_cfg_list } = this.styleData.components[0];
        const index = node_batch_cfg_list.findIndex(item => item.id === val.id);
        node_batch_cfg_list.splice(index, 1);
        if (val.id === this.actStyleBatch.id) {
          index > 0 ? this.switchStyleBatch(node_batch_cfg_list[index - 1]) : this.switchStyleBatch();
        }
      }).catch(e => e);
    },
    alidator (rule, value, callback) {
      if (!value) {
        return callback(new Error('名字不能为空!'));
      }
      const repeatName = this.questionBatch.find(item => item.name === value && item.id !== this.form.id);
      if (repeatName) return callback(new Error('名字已重复!'));
      callback();
    },
    dialogClose () {
      this.form = { name: '' };
    },
    addConfig () {
      this.dialogVisible = true;
    },
    watchTab (index) {
      if (index === 0) {
        this.switchQuestionType(this.styleClasses[0]);
      } else {
        this.queryBatch();
      }
    },
    queryBatch () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      if (!node_batch_cfg_list) this.$set(this.styleData.components[0], 'node_batch_cfg_list', []);
      this.switchStyleBatch();
    },
    switchStyleBatch (val) {
      if (!this.questionBatch.length) {
        this.actStyleBatch = {};
        this.config = null;
        return;
      }
      this.conditions = {
        listKeyword: '',
        currentPage: 1,
      };
      if (val) {
        if (val.id === this.actStyleBatch.id) return;
        this.actStyleBatch = this.questionBatch.find(item => item.id === val.id);
      } else {
        this.actStyleBatch = this.questionBatch[0];
      }
      this.config = this.actStyleBatch;
      const { class_cfg_list, default_style } = this.styleData.components[0];
      const questionClass = class_cfg_list.find(item => item.identity.node_type === 'question' && !item.identity.content?.question_type && !item.identity.content?.level && !item.identity.tag_key);
      this.placeholder = mergePeer(mergeDefault(defaultQuestion(), default_style), questionClass);
    },
    changeDisplay (val) {
      if (val === 'block' || val === 'float') {
        this.config.html._self.img.align = 'left';
      } else {
        this.config.html._self.img.align = '';
      }
      this.$set(this.config.html._self.img, 'insert_at', val === 'float' ? 'body' : '');
    },
    setDisplay (v, obj, k) {
      obj[k] = v;
    },
    getDisplay (obj, upObj, k) {
      const v = obj[k];
      const upV = upObj[k];
      if (typeof v !== 'boolean') {
        if (k === 'serial_number' && this.actQuestionType.key === 'material') {
          return false;
        }
        if (typeof upV !== 'boolean') {
          return true;
        }
        return upV;
      }
      return v;
    },
    selectFormat (item) {
      this.actFormat = item;
      Object.assign(this.config, _.cloneDeep(item.data.data));
    },
    setComponent () {
      const config = _.cloneDeep(this.config);
      delete config.name;
      delete config.id;
      delete config.node_id_list;
      delete config.node_type;
      delete config.identity;
    },
    switchQuestionType (val) {
      let { children } = val;
      children = children.filter(i => i);
      this.actQuestionType = { ...val, children };
      this.actStyle = _.cloneDeep(children[0]);
      this.actFormat = { data: {} };
    },
    switchStyleClass (val) {
      const { node_type, level, question_type, tag_key, model } = val;
      const { class_cfg_list, default_style } = this.styleData.components[0];
      let target = class_cfg_list.find(item => {
        const { identity: { node_type: identityNodeType, tag_key: identityTagKey, content } } = item;
        if (content?.level === level && identityTagKey === tag_key
        && content?.question_type === question_type && node_type === identityNodeType) {
          return true;
        }
      });
      if (!target) {
        target = defaultQuestion({ identity: { node_type, tag_key, content: { level, question_type } } });
        const weight = (tag_key ? 1 : 0) + (level ? 2 : 0) + (question_type ? 3 : 0);
        const temp = class_cfg_list.filter(item => { // 过滤出试题部分, tag_key优先级1，level优先级2, question_type优先级3。拿到优先级比当前config大的
          const { node_type, tag_key, content } = item.identity;
          if (node_type !== 'question') return;
          const { level, question_type } = content || {};
          const itemWeight = (tag_key ? 1 : 0) + (level ? 2 : 0) + (question_type ? 3 : 0);
          if (itemWeight > weight) return true;
        });
        if (temp.length) {
          const index = class_cfg_list.indexOf(temp[0]);
          class_cfg_list.splice(index, 0, target);
        } else {
          class_cfg_list.push(target);
        }
      }
      this.config = target;
      const upOneLevel = class_cfg_list.filter((item, index) => index < class_cfg_list.indexOf(target)
        && item.identity.node_type === 'question'
        && (!item.identity.content?.question_type || item.identity.content?.question_type === target.identity.content?.question_type));
      this.placeholder = mergePeer(mergeDefault(defaultQuestion(), default_style), ...upOneLevel);
      this.condition.formatModel = model;
    },

    switchFormat (val) {
      this.condition.formatType = val;
    },
  }
};
</script>
<style lang="scss">
.style-tool-tooltip {
  overflow: auto;
}
.style-tool-mark {
  font-weight: 700;
  color: #606266;
  background-color: yellow;
}
.style-demo-paragraph-config-pop {
  padding: 0;
  .style-demo-paragraph-config-pop-list {
    /deep/ .el-button--text {
      border: unset;
      background-color: unset;
      color:#198cff;
      font-size: 12px;
      padding: 0;
      min-width: unset;
      &:hover {
        background-color: unset;
        color:#66b1ff;
      }
    }
    .pop-header {
      height: 60px;
      padding: 0 12px 0 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e4e4;
      .pop-title {
        font-size: 16px;
        font-weight: 500;
        color: #18191A;
        flex: auto;
      }
      .pop-control {
        flex: none;
      }
    }
    .empty {
      height: 300px;
      line-height: 300px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #616665;
    }
    .pop-list {
      max-height: 300px;
      overflow-y: scroll;
      .pop-list-item {
        height: 60px;
        border-bottom: 1px solid #e5e4e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 23px;
        overflow: hidden;
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #616665;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        /deep/ .el-button--text {
          color: #A2A2A2;
          &:hover {
            color: #d5d9de;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
  $main-color: #409EFF;
  $danger-color: #F56C6C;
  .format-style-form-item {
    /deep/ > .el-form-item__content {
      width: 100%;
    }
  }
  .decorations-100 {
    /deep/ .decoration-title {
      width: 100px;
    }
  }
  .base-item {
    flex: none;
    display: flex;
    align-items: center;
    padding: 10px;
    .base-label {
      height: 17px;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      text-align: right;
    }
  }
  .content-body {
    /deep/ p {
      margin: 0;
      display: inline;
    }
  }
  .node-select-container {
    border: 1px solid #ccc;
    height: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    .node-condition-container {
      flex: none;
      display: flex;
      padding: 4px;
      .search-container {
        flex: auto;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 4px;
        .search-input {
          width: 200px;
        }
      }
      .pop-toggle {
        min-width: unset;
        padding: 12px 12px;
        margin-left: 4px;
      }
    }
    .list {
      padding: 10px 20px;
      flex: auto;
      overflow-y: scroll;
      .list-header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        .list-header-left {
          padding-left: 10px;
          flex: auto;
        }
        .list-header-right {
          flex: none;
          display: flex;
          .style-tool-button--text {
            margin-right: 10px;
            color: rgb(48, 49, 51);
          }
          .main-color {
            color: $main-color
          }
          /deep/ .el-checkbox__label {
            width: unset;
            padding-right: 14px;
          }
        }
      }
      .list-container {
        border: 1px solid #DEDEDE;
        flex: auto;
        overflow: hidden;
      }
      .list-item {
        font-size: 12px;
        height: 50px;
        color: #919599;
        .list-item-path {
          padding-left: 30px;
          width: auto;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
    }
    .footer {
      flex: none;
      text-align: center;
      padding: 10px;
    }
  }
  .bantch-tabs {
    position: absolute;
    left: 20px;
    top: 0;
    transform: translate(0, -100%);
    width: calc(100% - 21px);
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: #ccc;
    }
  }
  .question-config {
    /deep/ .el-card__body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .header {
      flex: none;
      display: flex;
      align-items: center;
      .inline-tab {
        /deep/ .tabs-item {
          min-width: 100px;
        }
        /deep/ .slide {
          transition: transform .2s;
        }
      }
    }
    .body {
      margin-top: 4px;
      height: calc(100% - 46px);
      .menu {
        height: 100%;
        border: 1px solid #ccc;
        position: relative;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 60px;
          text-align: center;
          flex: none;
          .f-r-btn {
            float: right;
            line-height: 60px;
            margin-right: 20px;
          }
        }
        /deep/ .el-menu {
          flex: auto;
          overflow-y: scroll;
        }
        /deep/ .el-menu-item.is-active::before {
          background-color: unset;
        }
        /deep/ .el-menu-item {
          margin-bottom: 10px;
          background-color: #F2F6FC;
        }
        /deep/ .el-menu-item.is-active {
          background-color: #dae1e8;
        }
        .menu-item {
          display: flex;
          justify-content: space-between;
          .del-buton {
            background-color: unset;
            padding: 0;
            color: #A2A2A2;
            min-width: unset;
            font-size: 12px;
            border: none;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      /deep/ .splitter-pane-resizer {
        background: #fff;
        opacity: 1;
        // border: 1px solid #0003;
        z-index: 1111;
        // box-shadow: 0px 0px 3px #bdbfb4;
      }
      /deep/ .splitter-paneR {
        padding-left: 6px!important;
      }
      /deep/ .splitter-paneL {
        padding-right: 5px!important;
      }
      .body-right {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .style-lib {
        flex: none;
        position: relative;
        border: 1px solid #ddd;
        .style-lib-tabs {
          padding: 0 20px;
          /deep/ .el-tabs__header {
            margin-bottom: unset;
          }
          /deep/ .el-tabs__item {
            height: 50px;
          }
          .tab-item {
            display: flex;
            flex-direction: column;
            .tab-item-img {
              flex: none;
              height: 30px;
            }
            .tab-item-text {
              flex: none;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .toggle {
          position: absolute;
          right: -1px;
          top: 0;
          transform: translate(0, -100%);
          border: 1px solid #ccc;
          border-bottom-color: transparent;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 4px;
          background: #fff;
          cursor: pointer;
          .el-icon-caret-top {
            transition: transform .2s;
          }
        }
        .condition-box {
          display: flex;
          align-items: center;
          color: #333;
          height: 40px;
          overflow: hidden;
          transition: all 0.2s;
          .left {
            flex: auto;
            display: flex;
            align-items: center;
            overflow-x: auto;
            .format-item {
              font-size: 13px;
              padding: 2px 4px;
              padding-top: 0.1px;
              margin-left: 20px;
              color: #666;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                color: #fff;
                background: $main-color;
              }
            }
            .act-item {
              color: #fff;
              background: $main-color;
            }
          }
          .right {
            flex: none;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 10px;
            i {
              color: #C0C4CC;
            }
            input {
              outline: none;
              border: none;
              border-bottom: 1px solid #ccc;
              padding-left: 10px;
              width: 120px;
              font-size: 13px;
              color: #919699;
              &::-webkit-input-placeholder {
                color: #DCDFE6;
              }
            }
          }
        }
        .style-box {
          display: flex;
          flex-wrap: wrap;
          overflow: scroll;
          padding-bottom: 15px;
          max-height: 440px;
          .item {
            display: inline-block;
            width: 150px;
            padding-top: 10px;
            height: 90px;
            border: 1px solid #ddd;
            background-color: #fafafa;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 15px;
            position: relative;
            font-size: 12px;
            margin-top: 15px;

            &:hover {

              .del {
                display: unset;
              }
              .button-box {
                display: flex;
              }
              .text-box {
                display: none;
              }
            }
            .del {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              z-index: 2;
              display: none;
            }
            .img {
              width: 90%;
              height: 40px;
              flex: none;
            }
            .button-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: none;
            }
            .text-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: flex;
              .el-icon-success {
                color: $main-color;
                margin-right: 6px;
              }
            }

          }
          .act-item {
            background-color: #409eff1f;
          }
        }
      }
      .closed {
        border-color: transparent;
        .toggle {
          border: 1px solid #ccc;
          border-radius: 4px;
          .el-icon-caret-top {
            transform: rotate(180deg);
          }
        }
        .condition-box {
          height: 0;
        }
        .style-box {
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        .style-lib-tabs {
          height: 0;
          overflow: hidden;
        }
      }
      .preview {
        flex: none;
        max-height: 200px;
        background-color: #F2F6FC;
      }
      .config {
        flex: auto;
        overflow-y: hidden;
        position: relative;
        .fk-config {
          /deep/ .el-checkbox {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .config-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        /deep/ .el-radio__label {
          width: unset;
        }
        /deep/ .el-checkbox__label {
          width: unset;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
          padding: 10px;
          margin-right: unset;
        }
        .label, /deep/ .el-form-item__label, /deep/ .el-form-item__content {
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
        /deep/ .el-form-item__label {
          padding-right: 0;
        }
        /deep/ .el-tabs {
          height: calc(100% - 48px);
          .el-tabs__content {
            // overflow-y: scroll;
            height: 100%;
            padding-right: 20px;
            padding-top: 20px;
          }
        }
        /deep/ .el-form,form{
          padding-bottom: 50px !important;
        }
      }
    }
  }
  .showall{
    margin-bottom: 10px;
  }
</style>
