import _ from 'lodash';

export const checkLevel = (node_level) => {
  if (typeof node_level !== 'string') node_level = node_level.toString().trim();
  if (node_level === '') return '';
  if (/^([0-9]|(1[0-5])){1}$/.test(node_level)) return +node_level;
  if (/^(([0-9]|(1[0-5])),\s*)(([0-9]|(1[0-5])),\s*)*([0-9]|(1[0-5]))$/.test(node_level)) {
    return node_level.replaceAll(' ', '').split(',').map(item => +item);
  }
  if (/^([0-9]|(1[0-5]))\s*-\s*([0-9]|(1[0-5]))$/.test(node_level)) {
    const startLevel = +node_level.split('-')[0];
    const arr = [+startLevel];
    const endLevel = +node_level.split('-')[1];
    while (arr[arr.length - 1] < endLevel) {
      arr.push(arr[arr.length - 1] + 1);
    }
    return arr;
  }
  return '';
};

export function checkColor (color) {
  if (color.length < 3) {
    return true;
  }
  color = `#${color}`;
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  return reg.test(color);
}

export function hasOwn (val, key) {
  return hasOwnProperty.call(val, key);
}

export function mergeDefault (target, ...sources) { // 合并默认数据
  Object.keys(target).forEach(item => {
    if (target[item] !== null && typeof target[item] === 'object' && !Array.isArray(target[item])) { // value是对象的递归向下
      mergeDefault(target[item], ...sources.map(source => source && source[item] ? source[item] : source));
    } else { // 其它的替换
      sources.forEach(source => { // 替换的对象不是对象，并且存在进行替换
        if (source && source[item] !== undefined && source[item] !== null && source[item] !== '' && Object.prototype.toString.call(source[item]) !== '[object Object]') target[item] = source[item];
      });
    }
  });
  return target;
}

export function mergePeer (target, ...sources) { // 合并多个相同数据结构的数据
  Object.keys(target).forEach(item => {
    if (target[item] !== null && typeof target[item] === 'object' && !Array.isArray(target[item])) { // value 是对象的递归向下
      mergePeer(target[item], ...sources.map(source => {
        if (source && source[item]) {
          return source[item];
        }
      }).filter(item => item !== undefined));
    } else { // 其它的替换
      sources.forEach(source => { // 替换的对象不是对象，并且存在进行替换
        if (source && source[item] !== undefined && source[item] !== null && source[item] !== '' && Object.prototype.toString.call(source[item]) !== '[object Object]') target[item] = source[item];
      });
    }
  });
  return target;
}

export function checkType (value) { // 保存前进行一次类型检查
  if (Array.isArray(value)) {
    value.forEach(item => {
      if (typeof item === 'object') { // value是对象的递归向下
        checkType(item);
      }
    });
  } else {
    Object.keys(value).forEach(item => {
      if (typeof value[item] === 'object') { // value是对象的递归向下
        checkType(value[item]);
      } else { // 其它的替换
        if (item === 'level' || item === 'node_level') {
          value[item] = checkLevel(value[item]);
        }
        if (item === 'line_height' || item === 'indent_unit_length' || item === 'bracket_length') {
          value[item] = Number(value[item]);
        }
        if (item === 'color' && !checkColor(value[item])) {
          value[item] = '000000';
        }
      }
    });
  }
  return value;
}

export function findKey (value, obj, compare = (a, b) => a === b) {
  return Object.keys(obj).find(k => compare(obj[k], value));
}

export function removeIfEmpty (d) { // 移除对象内的空数组，undefined, null, ''
  if (!d || typeof d !== 'object') {
    return d;
  }
  if (Array.isArray(d)) {
    const res = d.map(i => removeIfEmpty(i)).filter(i => i !== null || i !== '');
    const filterRes = res.filter(item => item != null);
    if (!filterRes.length) return;
    return filterRes;
  }
  const entities = [];
  Object.keys(d).forEach(k => {
    const v = removeIfEmpty(d[k]);
    if (v == null || v === '') return;
    entities.push([k, v]);
  });
  if (!entities.length) return;
  const res = {};
  entities.forEach(([k, v]) => {
    res[k] = v;
  });
  return res;
}

export function merge (...args) {
  if (args.length < 2) return args[0];
  if (args.length === 2) {
    const [obj, other] = args;
    if (!obj || !other) return other;
    if (typeof obj !== typeof other) return other;
    if (Array.isArray(obj) || Array.isArray(other)) return other;
    if (typeof obj !== 'object') return other;
    const res = {};
    Object.keys(obj).forEach(key => {
      if (!hasOwn(other, key)) {
        res[key] = obj[key];
      } else {
        res[key] = merge((obj)[key], (other)[key]);
      }
    });
    Object.keys(other).forEach(key => {
      if (!hasOwn(obj, key)) {
        res[key] = other[key];
      }
    });
    return res;
  }
  const [obj, other, ...rest] = args;
  let res = merge(obj, other);
  for (const arg of rest) {
    res = merge(res, arg);
  }
  return res;
}

export function setVal (obj, target, val) {
  // setVal({}, 'a.b.c', 'aa') => {a: {b: {c: 'aa'}}}
  const targetList = target.split('.');
  targetList.forEach((item, index) => {
    if (index === targetList.length - 1) {
      obj[item] = val;
    } else {
      if (!obj[item]) obj[item] = {};
      obj = obj[item];
    }
  });
}
export function getVal (obj, target) {
  // setVal({}, 'a.b.c', 'aa') => {a: {b: {c: 'aa'}}}
  const targetList = target.split('.');
  let result;
  targetList.forEach((item, index) => {
    if (index === targetList.length - 1) {
      result = obj[item];
    } else {
      if (!obj[item]) {
        obj = {};
        return;
      }
      obj = obj[item];
    }
  });
  return result;
}
export function setId () {
  return Number(Math.random().toString().substr(3, 12) + Date.now()).toString(36);
}

export function getDPI () {
  const DPI = {};
  if (window.screen.deviceXDPI !== undefined) {
    DPI.x = window.screen.deviceXDPI;
    DPI.y = window.screen.deviceYDPI;
  } else {
    const tmpNode = document.createElement('DIV');
    tmpNode.style.cssText = 'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden';
    document.body.appendChild(tmpNode);
    DPI.x = parseInt(tmpNode.offsetWidth);
    DPI.y = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return DPI;
}
// 去除空属性(不包括对象中的对象)
export const removeEmpty = (obj) => {
  const o = _.cloneDeep(obj);
  for (const i in o) {
    if (o[i] === '' || o[i] === null) {
      delete o[i];
    }
  }
  return o;
};
