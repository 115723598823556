export const questionTypeMap = {
  'ture_or_false': '判断题',
  'choice': '选择题',
  'blank': '填空题',
  'material': '材料题',
  'other': '作答题'
};

export const nodeTypeMap = {
  'chapter': '标题',
  'paragraph': '段落',
  'question': '试题'
};

// 样式库顶栏配置
export const subTitle = [{
  name: '目录浏览',
  icon: 'catalog'
}, {
  name: '版面配置',
  icon: 'book'
}, {
  name: '内容配置',
  icon: 'content'
}, {
  name: '页眉',
  icon: 'content'
}, {
  name: '页脚',
  icon: 'content'
}, {
  name: '水印',
  icon: 'content'
}, {
  name: '左边栏',
  icon: 'content'
}, {
  name: '右边栏',
  icon: 'content'
}];

export const marginTypeMap = {
  '页眉': 'header',
  '页脚': 'footer',
  '左边栏': 'left',
  '右边栏': 'right',
  '水印': 'watermark'
};

export const marginTools = [{
  name: '文本',
  icon: 'menuTool0',
  rules: ['页眉', '页脚']
}, {
  name: '图片',
  icon: 'menuTool1',
  rules: ['页眉', '页脚', '水印', '左边栏', '右边栏']
}, {
  name: '书名',
  icon: 'menuTool2',
}, {
  name: '章节',
  icon: 'menuTool3'
}, {
  name: '页码',
  icon: 'menuTool4',
  rules: ['页眉', '页脚']
}, {
  name: '眉线',
  icon: 'menuTool5'
}];

export const htmlStyleCleanList = [
  { label: '加粗', value: 'bold' },
  { label: '倾斜', value: 'italics' },
  { label: '下划线', value: 'underline' },
  { label: '删除线', value: 'strike' },
  { label: '下标', value: 'subscript' },
  { label: '上标', value: 'superscript' },
  { label: '双下划线', value: 'emphasis_double' },
  { label: '波浪线', value: 'emphasis_wave' },
  { label: '着重号', value: 'emphasis_dot' },
  { label: '文本对齐', value: 'text_align' },
  { label: '段落缩进', value: 'indent' },
];

function getfontSize () {
  const data = [{ key: '初号', value: '初号' },
    { key: '小初', value: '小初号' },
    { key: '一号', value: '一号' },
    { key: '小一', value: '小一号' },
    { key: '二号', value: '二号' },
    { key: '小二', value: '小二号' },
    { key: '三号', value: '三号' },
    { key: '小三', value: '小三号' },
    { key: '四号', value: '四号' },
    { key: '小四', value: '小四号' },
    { key: '五号', value: '五号' },
    { key: '小五', value: '小五号' },
    { key: '六号', value: '六号' },
    { key: '小六', value: '小六号' },
    { key: '七号', value: '七号' },
    { key: '5', value: '5pt' },
    { key: '5.5', value: '5.5pt' },
    { key: '6', value: '6pt' },
    { key: '6.5', value: '6.5pt' },
    { key: '7', value: '7pt' },
    { key: '7.5', value: '7.5pt' },
    { key: '8', value: '8pt' },
    { key: '8.5', value: '8.5pt' },
    { key: '9', value: '9pt' },
    { key: '9.5', value: '9.5pt' },
    { key: '10', value: '10pt' },
    { key: '10.5', value: '10.5pt' },
    { key: '11', value: '11pt' },
    { key: '11.5', value: '11.5pt' },
    { key: '12', value: '12pt' },
    { key: '12.5', value: '12.5pt' },
    { key: '13', value: '13pt' },
    { key: '13.5', value: '13.5pt' },
    { key: '14', value: '14pt' },
    { key: '14.5', value: '14.5pt' },
    { key: '15', value: '15pt' },
    { key: '15.5', value: '15.5pt' }];
  let i = 16;
  while (i <= 72) {
    data.push({
      key: i,
      value: i + 'pt'
    });
    i++;
  }
  return data;
}
export const fontSize = getfontSize();

export const replaceFontSizeMap = {
  '初号': '42pt',
  '小初号': '36pt',
  '一号': '26pt',
  '小一号': '24pt',
  '二号': '22pt',
  '小二号': '18pt',
  '三号': '16pt',
  '小三号': '15pt',
  '四号': '14pt',
  '小四号': '12pt',
  '五号': '10.5pt',
  '小五号': '9pt',
  '六号': '7.5pt',
  '小六号': '6.5pt',
  '七号': '5.5pt',
};

export const pluginFontList = [
  { label: '等线', key: '等线' },
  { label: '等线 Light', key: '等线 Light' },
  { label: '方正姚体', key: '方正姚体' },
  { label: '华文彩云', key: '华文彩云' },
  { label: '华文仿宋', key: '华文仿宋' },
  { label: '华文楷体', key: '华文楷体' },
  { label: '华文隶书', key: '华文隶书' },
  { label: '华文宋体', key: '华文宋体' },
  { label: '华文细黑', key: '华文细黑' },
  { label: '华文新魏', key: '华文新魏' },
  { label: '华文行楷', key: '华文行楷' },
  { label: '华文中宋', key: '华文中宋' },
  { label: '微软雅黑', key: '微软雅黑' },
  { label: '微软雅黑 Light', key: '微软雅黑 Light' },
  { label: '新宋体', key: '新宋体' },
  { label: '幼圆', key: '幼圆' },
];

export const fontFamilyCn = {
  '宋体（默认）': 'SongTi',
  '黑体': 'HeiTi',
  '仿宋': 'Fangsong',
  '楷体': 'KaiTi',
  '小标宋': 'XiaoBS',
  '秀丽': 'Xiuli',
  '新秀丽': 'Xinxiuli',
  '中等线': 'Zhongdengxian',
  '细等线': 'Xidengxian',
  '大行楷': 'Daxingkai',
  '幼线': 'Youxian',
  '行楷': 'Xingkai',
  '平黑': 'Pinghei',
  '细黑一': 'Xihei',
  '超粗黑': 'Chaocuhei',
  '大黑': 'Dahei',
  '粗黑': 'Cuhei',
  '隶书': 'Lishu',
  '细圆': 'Xiyuan',
  '圆二': 'Yuaner',
  '准圆': 'Zhunyuan',
  '粗圆': 'Cuyuan',
  '黑变': 'Heibian',
  '大标宋': 'DaBS',
  '琥珀': 'Hupo',
  '水黑': 'Shuihei',
  '报宋': 'Baosong',
  '美黑': 'Meihei',
  '舒体': 'Shuti',
  '水柱': 'Shuizhu',
  '瘦金书': 'Shoujs',
  '细倩': 'Xiqian',
  '粗倩': 'Cuqian',
  '中倩': 'Zhongqian',
  '细珊瑚': 'Xishanshu',
  '粗宋': 'Cusong',
  '平和体': 'Pinght',
  '古隶': 'Guli',
  '硬笔楷书': 'YBKS',
  '硬笔行书': 'YBXS',
  '中楷': 'Zhongkai',
  '姚体': 'Yaoti',
  '综艺': 'Zongyi',
  '彩云': 'Caiyun',
  '康体': 'Kangti',
  '黄草': 'Huangcao',
  '胖娃': 'Pangwa',
  '艺黑': 'Yihei',
  '毡笔黑': 'Zhanbh',
  '日文黑': 'Riwh',
  '隶变': 'Libian',
  '新报宋': 'XinBS',
  '新舒体': 'XinST',
  '启体': 'Qiti',
  '小篆体': 'Xiaozhuan',
  '日文明': 'Riwm',
  '宋三': 'Songsan',
  '魏碑': 'Weibei',
  '隶二': 'Lier',
  '宋一': 'Songyi',
  '华隶': 'Huali',
  '稚艺': 'Zhiyi',
  '卡通': 'Katong',
  '等线': '等线',
  '等线 Light': '等线 Light',
  '方正姚体': '方正姚体',
  '华文彩云': '华文彩云',
  '华文仿宋': '华文仿宋',
  '华文楷体': '华文楷体',
  '华文隶书': '华文隶书',
  '华文宋体': '华文宋体',
  '华文细黑': '华文细黑',
  '华文新魏': '华文新魏',
  '华文行楷': '华文行楷',
  '华文中宋': '华文中宋',
  '华文琥珀': '华文琥珀',
  '微软雅黑': '微软雅黑',
  '微软雅黑 Light': '微软雅黑 Light',
  '新宋体': '新宋体',
  '幼圆': '幼圆',
};

export const fontFamilyEn = {
  '白正（默认）': 'Baizheng',
  '大圆': 'Dayuan',
  '数学体': 'Shuxue',
  '方黑1': 'FH1',
  '方黑1斜': 'FHX1',
  '方黑2': 'FH2',
  '方黑2斜': 'FHX2',
  '方黑3': 'FH3',
  '方黑3斜': 'FHX3',
  '方黑4': 'FH4',
  '方黑4斜': 'FHX4',
  '方黑5': 'FH5',
  '方黑5斜': 'FHX5',
  '方黑6': 'FH6',
  '方黑6斜': 'FHX6',
  '方黑7': 'FH7',
  '方黑7斜': 'FHX7',
  '方黑8': 'FH8',
  '方黑8斜': 'FHX8',
  '方黑9': 'FH9',
  '方黑9斜': 'FHX9',
  '白一斜': 'Baixie1',
  '白一正': 'Baizheng1',
  '白七斜': 'Baixie7',
  '白七正': 'Baizheng7',
  '白三斜': 'Baixie3',
  '白三正': 'Baizheng3',
  '白二斜': 'Baixie2',
  '白二正': 'Baizheng2',
  '白五斜': 'Baixie5',
  '白五正': 'Baizheng5',
  '白八斜': 'Baixie8',
  '白八正': 'Baizheng8',
  '白六斜': 'Baixie6',
  '白六正': 'Baizheng6',
  '白四斜': 'Baixie4',
  '白四正': 'Baizheng4',
  '白斜': 'Baixie',
  '空圆': 'Kongyuan',
  '细圆': 'Xiyuan',
  '黑一斜': 'Heixie1',
  '黑一正': 'Heizheng1',
  '黑七斜': 'Heixie7',
  '黑七正': 'Heizheng7',
  '黑三斜': 'Heixie3',
  '黑三正': 'Heizheng3',
  '黑二斜': 'Heixie2',
  '黑二正': 'Heizheng2',
  '黑五斜': 'Heixie5',
  '黑五正': 'Heizheng5',
  '黑八斜': 'Heixie8',
  '黑八正': 'Heizheng8',
  '黑六斜': 'Heixie6',
  '黑六正': 'Heizheng6',
  '黑四斜': 'Heixie4',
  '黑四正': 'Heizheng4',
  '黑斜': 'Heixie',
  '黑正': 'Heizheng',
  '花体': 'Huati',
  '花1体': 'Hua1',
  '花2体': 'Hua2',
  '方头斜': 'Fangxie',
  '白歌德体': 'Baigd',
  '方头正': 'Fangz',
  '细方黑正': 'Xifz',
  '细方黑斜': 'Xifx',
  '细体': 'Xiti',
  '细1体': 'Xiti1',
  '细1斜': 'Xixie1',
  '圆1体': 'Yuan1',
  '圆2体': 'Yuan2',
  '特体': 'Teti',
  '半宽白体': 'BankuanB',
  '半宽黑体': 'BankuanH',
  '半宽白斜': 'BankuanBX',
  '半宽黑斜': 'BankuanHX'
};

export const fontMargin = {
  '居左': 'left',
  '居中': 'center',
  '居右': 'right',
};

export const pageBreaks = {
  '新起一页': 'page',
  '新起一页，保持在左页': 'left',
  '新起一页，保持在右页': 'right',
  '新起一列': 'column'
};

export const webFontFamily = {
  HeiTi: 'SimHei, STHeiti, "微软雅黑"',
  SongTi: 'SimSun, STSong, NSimSun, FangSong',
  KaiTi: 'KaiTi, STKaiti, STKaiti',
  Fangsong: 'FangSong',
  default: '"SimSun", "Time News Roman"'
};
